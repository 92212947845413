// src/api/userAPI.js
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
const USERS_API_URL = `${API_BASE_URL}/api/users`;

// Function to handle user signup
export const signupUser = async (userData) => {
  try {
    const response = await fetch(`${USERS_API_URL}/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Signup failed');
    }

    return await response.json();
  } catch (error) {
    console.error("Signup Error:", error);
    throw error; // Rethrowing the error to be handled where the function is called
  }
};

export const loginUser = async (loginData) => {
    try {
      const response = await fetch(`${USERS_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Login failed');
      }
  
      const responseData = await response.json();
      // Optionally, handle the token here (e.g., storing it in localStorage)
      localStorage.setItem('token', responseData.token);
  
      return responseData;
    } catch (error) {
      console.error("Login Error:", error);
      throw error; // Rethrowing the error to be handled in the component
    }
  };

  export const fetchUserProfile = async (username) => {
    try {
      // console.log("started", username);
      const response = await fetch(`${USERS_API_URL}/user/${username}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response)
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch user profile');
      }
  
      return await response.json();
    } catch (error) {
      console.error("Fetch User Profile Error:", error);
      throw error; // Rethrowing the error to be handled in the component
    }
  };
  
  export const updateProfile = async (profileData) => {
    try {
      const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
      if (!token) {
        throw new Error('No token found');
      }
  
      const response = await fetch(`${USERS_API_URL}/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Assuming you use Bearer token for authorization
        },
        body: JSON.stringify(profileData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Profile update failed');
      }
  
      return await response.json();
    } catch (error) {
      console.error("Profile Update Error:", error);
      throw error; // Rethrowing the error to be handled in the component
    }
  };


  
