import React, { useState } from 'react';
import { sendResetPasswordEmail } from '../api/authAPI'; // Placeholder for your API call function

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendResetPasswordEmail(email);
      setMessage('Check your email for the password reset link.');
    } catch (error) {
      setMessage('Failed to send reset link. Please try again.');
    }
  };

  return (
    <div className="forgot-password-page">
      <h2>Reset Password</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <label>
          Email
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <button type="submit">Send Reset Link</button>
      </form>
    </div>
  );
}

export default ForgotPasswordPage;
