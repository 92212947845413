// src/api/reviewAPI.js
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
const REVIEWS_API_URL = `${API_BASE_URL}/api/reviews`;
const fetchReviews = async (queryParams = '') => {
  try {
    const response = await fetch(`${REVIEWS_API_URL}?${queryParams}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    // Handle or throw the error depending on your error handling strategy
    console.error("Error fetching reviews:", error);
    throw error;
  }
};


//TODO add token info here create review is protected
const createReview = async (reviewData) => {
  // Fill in any missing fields with default values
  const completeReviewData = {
    title: reviewData.title || 'Untitled', // Default title if none provided
    text: reviewData.text || 'Txt is Empty',
    companyName: reviewData.companyName || 'Unknown Company', // Example of a default company name
    year: reviewData.year || new Date().getFullYear(), // Current year as default
    role: reviewData.role || 'Unknown Role', // Example of a default role
    location:reviewData.location || 'Unknown Location',
    // Include other fields as necessary with their default values
  };

  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${REVIEWS_API_URL}`, {
      method: 'POST',
      headers: {
        'Authorization' : `Bearer ${token}`,
        'Content-Type': 'application/json',
        // Include other headers as necessary, like authorization tokens
      },
      body: JSON.stringify(completeReviewData), // Convert the review data to JSON string
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error creating review:", error);
    throw error;
  }
};

const fetchArticleById = async (id) => {
  try {
    const response = await fetch(`${REVIEWS_API_URL}/${id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching review with ID ${id}:`, error);
    throw error;
  }
};

//TODO send token as param to this function accessing from context
const updateReview = async (id, reviewData) => {
  // Retrieve the token from storage
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  try {
    const response = await fetch(`${REVIEWS_API_URL}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // Include the Authorization header with the token
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(reviewData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error(`Error updating review with ID ${id}:`, error);
    throw error;
  }
};


//TODO send tooken as param
const deleteArticle = async (id) => {
  try {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const response = await fetch(`${REVIEWS_API_URL}/${id}`, {
      method: 'DELETE',
      headers: {
        // Include the Authorization header with the token
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return 'Review deleted successfully';
  } catch (error) {
    console.error(`Error deleting review with ID ${id}:`, error);
    throw error;
  }
};


// Fetch likes for an article - returns JSON structure
const fetchLikesForArticle = async (articleId, token = null) => {

  try {

    const url = `${REVIEWS_API_URL}/${articleId}/likes`;
    const options = token?{
      headers:{
        'Authorization' : `Bearer ${token}`
      }
    } : {};

    const response = await fetch(url, options);
    if (!response){
      throw new Error(`Error: ${response.status}`);
    }
    return await response.json()
  } catch (error){
    console.error('Failed to fetch likes for article:', error);
    throw error
  }
};

//TODO token as param
// returns json field, todetermin what values
const addLikeToArticle = async (articleId) => {
  try {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const url = `${REVIEWS_API_URL}/${articleId}/likes`;
    const options = {
      method:'POST',
      headers:{
        'Authorization':`Bearer ${token}`
      }
    };
    const response = await fetch(url, options);
    if (!response){
      throw new Error(`Error: ${response.status}`);
    }
    
    } catch (error){
      console.error('Failed to add like to article', error);
      throw error
    }
};

// Remove a like from an article
const removeLikeFromArticle = async (articleId) => {
  try {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const url = `${REVIEWS_API_URL}/${articleId}/likes`;
    const options = {
      method:'DELETE',
      headers:{
        'Authorization':`Bearer ${token}`
      }
    };

    const response = await fetch(url, options);
    if (!response){
      throw new Error(`Error: ${response.status}`);
    }
    
    } catch (error){
      console.error('Failed to delete like from article', error);
      throw error
    }
};


// Adjust the export syntax based on your environment (CommonJS or ES Modules)
module.exports =  { 
  fetchReviews, 
  createReview, 
  fetchArticleById, 
  updateReview, 
  deleteArticle,
  fetchLikesForArticle,
  addLikeToArticle,
  removeLikeFromArticle
 };
