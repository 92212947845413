import React, { createContext, useContext, useState, useEffect } from 'react';
import { renewTokenAPI } from '../api/utilAPI'; // Adjust the import path as needed

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

const renewToken = async () => {
    try {
        const token = localStorage.getItem('token');
        const data = await renewTokenAPI(token);
        if (data && data.token) {
            localStorage.setItem('token', data.token);
            return data.token;
        } else {
            throw new Error("Token renewal failed - no new token returned");
        }
    } catch (error) {
        console.error("Token renewal failed:", error);
        localStorage.removeItem('token');
        return null;
    }
};

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initUser = async () => {
            const storedUser = localStorage.getItem('user');
            let token = localStorage.getItem('token');

            if (token) {
                // Optionally renew the token before setting the user
                const newToken = await renewToken(); // Renew the token if necessary
                if (newToken) {
                    token = newToken; // Use the renewed token if available
                } else {
                    // Handle case where token renewal fails, e.g., by resetting user state or redirecting to login
                    setUser(null);
                    setIsLoading(false);
                    return;
                }

                setUser({ token, ...(storedUser ? JSON.parse(storedUser) : {}) });
            }

            setIsLoading(false);
        };

        initUser();
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser, isLoading }}>
            {children}
        </UserContext.Provider>
    );
};
