import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate hook
import logoImage from '../assets/image/logo1.webp';
import { loginUser } from '../api/userAPI';
import '../css/LoginPage.css';
import { useUser } from '../contexts/UserContext';


function LoginPage() {
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(''); // State to handle login error
  const navigate = useNavigate(); // Hook to programmatically navigate
  const { setUser } = useUser();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message before a new login attempt
    try {
      const responseData = await loginUser(loginData);      
      // console.log('Login successful:', responseData);
      // console.log("TOKEN STORED IN LOCAL STORAGE ", localStorage.getItem('token'));
      setUser(responseData.user); 
      // console.log(responseData.user.username)
      localStorage.setItem('user', JSON.stringify(responseData.user));
      navigate('/'); // Navigate to dashboard or home page after login
    } catch (error) {
      console.error('Login failed:', error.message);
      setError(error.message || 'Failed to login. Please try again.'); // Set error message
    }
  };

  return (
    <main id="tt-pageContent" className="tt-offset-none">
      <div className="container">
        <div className="tt-loginpages-wrapper">
          <div className="tt-loginpages">
            <a href="/" className="tt-block-title">
              <img src={logoImage} alt="Logo" className="login-logo" />
              <div className="tt-title">Welcome to InterviewBlogs</div>
              <div className="tt-description">
                Log into your account to unlock the true power of the community.
              </div>
            </a>
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            <form className="form-default" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="loginUserEmail">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  id="loginUserEmail"
                  placeholder="email@example.com"
                  value={loginData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="loginUserPassword">Password</label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  id="loginUserPassword"
                  placeholder="********"
                  value={loginData.password}
                  onChange={handleChange}
                />
              </div>
              <div className="row align-items-center">
                <div className="col-auto">
                  <div className="form-group">
                    <div className="checkbox-group">
                      <input
                        type="checkbox"
                        id="settingsCheckBox01"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                      <label htmlFor="settingsCheckBox01">
                        Remember me
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-auto ml-auto">
                  <Link to="/forgot-password" className="tt-underline">Forgot Password?</Link>
                </div>
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-secondary btn-block">Log in</button>
              </div>
              <p>Don't have an account? <a href="/signup" className="tt-underline">Sign up here</a></p>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default LoginPage;
