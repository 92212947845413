import React, { useState } from 'react';
import '../css/FilterBar.css';
import { Link } from 'react-router-dom';
import AddArticlePage from './AddArticlePage'; 
import { useUser } from '../contexts/UserContext';
// TODO ADD dropdown location to filter bar.
function FilterBar({ onSearch }) {
  const [companyName, setCompanyName] = useState('');
  const [role, setRoleName] = useState('');
  const [location, setLocationName] = useState('');
  // const [roleName, setRoleName] = useState('');
  // const [locationName, setLocationName] = useState('');
  const { user } = useUser();

  const handleSearch = (e) => {
    e.preventDefault(); // Prevent form submission from reloading the page
    onSearch({ companyName, role, location});
  };

  return (
  <div className="filter-bar-container">
    <form className="filter-bar" onSubmit={handleSearch}>
      <input
        type="text"
        placeholder="Company Name"
        value={companyName}
        onChange={e => setCompanyName(e.target.value)}
        className="filter-input"
      />
      <input
        type="text"
        placeholder="Role"
        value={role}
        onChange={e => setRoleName(e.target.value)}
        className="filter-input"
      />
      <input
        type="text"
        placeholder="Location"
        value={location}
        onChange={e => setLocationName(e.target.value)}
        className="filter-input"
      />
      <button type="submit" className="filter-search-btn">Search</button>
      {/* <button type="button" href="/add-article" className="filter-search-btn">Add Article</button> */}
      {/* <Link to="/add-article" className="filter-search-btn">Add Article</Link> */}
      {user && (
      <Link to="/add-article" className="filter-search-btn">Add Article</Link>
    )}
   
    </form>
    </div>

  );
}

export default FilterBar;
