// Footer.js
import React from 'react';
import "../css/Footer.css" // Assuming you've saved the above CSS in Footer.css

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-section">
            <h4>Contact Us</h4>
            <p>Email: hello@interviewblogs.com</p>
            {/* <p>Phone:+1 (317) 975-0112</p> */}
          </div>
          <div className="footer-section">
          <p>Made with ❤️ </p>
        </div>
          <div className="footer-section">
            <h4>Follow Us</h4>
            <a href="https://twitter.com">Twitter</a> |
            <a href="https://facebook.com">Facebook</a> |
            <a href="https://instagram.com">Instagram</a>
          </div>
        </div>
        
      </div>
    </footer>
  );
}

export default Footer;
