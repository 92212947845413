const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
const UTIL_API_URL = `${API_BASE_URL}/api/utils`;

const cityAutoComplete = async(searchTerm='') =>{
    try{
        const response = await fetch(`${UTIL_API_URL}/autocomplete/location/${encodeURIComponent(searchTerm)}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return await response.json();
  } catch (error) {
    console.error("Error fetching reviews:", error);
    throw error;
  }
    };

const roleAutoComplete = async (searchTerm ) => {
      try {

        const response = await fetch(`${UTIL_API_URL}/autocomplete/role/${encodeURIComponent(searchTerm)}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return await response.json();
      } catch (error) {
        console.error("Error fetching role suggestions:", error);
        throw error;
      }
    };


const renewTokenAPI = async(token) => {
  try {
    const response = await fetch(`${UTIL_API_URL}/auth/renew`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });
    return response.json()
  } catch (error) {
      console.error("Token Renewal Failed");
      return null;
  }
};
    
module.exports = {renewTokenAPI, cityAutoComplete, roleAutoComplete};