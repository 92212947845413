import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

const ProtectedRoute = ({ children }) => {
const { user, isLoading } = useUser();

    if (isLoading) {
      return <div>Loading...</div>;
    }
  
  if (!user) {
    // User is not logged in, redirect to the login page
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
