// App.js or index.js based on your project structure
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from '../src/js/MainPage'
import Footer from './js/Footer';
import Header from './js/Header';
import LoginPage from './js/LoginPage'; // Ensure the path is correct
import AddArticlePage from './js/AddArticlePage';
import SignupPage from './js/SignupPage';
import ProfilePage from './js/ProfilePage';
import ProtectedRoute from './js/ProtectedRoute'
import ArticleDetail from './js/ArticleDetail'; // Ensure you import ArticleDetail
import { UserProvider } from './contexts/UserContext';
import { ThemeProvider } from 'styled-components';
import UserProfile from './js/UserProfile';
import ForgotPasswordPage from './js/ForgotPasswordPage';
import ResetPasswordPage from './js/ResetPasswordPage';

function App() {
  return (
    <UserProvider>
    <Router>
    <div className="App">
      <Header />
      <div className="content-wrap">
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/articles/:id" element={<ArticleDetail />} /> {/* Corrected for React Router v6 */}
        <Route path="/add-article" element={<ProtectedRoute><AddArticlePage /></ProtectedRoute>} /> {/* New route for adding article */}
        {/* You can add more routes here */}
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
        <Route path="/user/:username" element={<UserProfile />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage/>} />
        <Route path="/reset-password" element={<ResetPasswordPage/>} />

      </Routes>
      
    </div>
    <Footer />
    </div>
    </Router>
    </UserProvider>
  );
}

export default App;
