// ThemeSwitcher.js
import React from 'react';
import '../css/style.css';
import { MdDarkMode } from 'react-icons/md';
import { WiDaySunny } from 'react-icons/wi';
import { MdOutlineDarkMode } from "react-icons/md";
const ThemeSwitcher = ({ isDarkMode, onToggle }) => (
  <div className="theme-switcher">
    {isDarkMode ? (
      <WiDaySunny onClick={onToggle} style={{ cursor: 'pointer' }} />
    ) : (
      <MdOutlineDarkMode onClick={onToggle} style={{ cursor: 'pointer' }} />
    )}
  </div>
);

export default ThemeSwitcher;
