// ArticlePreview.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../css/ArticlePreview.css'; // Ensure this path matches your project structure

const ArticlePreview = ({ article }) => (
  <Link to={`/articles/${article.id}`} className="article-preview-link">
    <div className="article-preview">
      <div className="preview-title">{article.title}</div>
      <div className="preview-detail"><strong>Company:</strong> {article.companyName}</div>
      <div className="preview-detail"><strong>Year:</strong> {article.year}</div>
      <div className="preview-detail"><strong>Position:</strong> {article.role}</div>
      <div className="preview-detail"><strong>Details:</strong> {article.title}</div>
      {/* Consider handling summary outside the single line if it's lengthy */}
    </div>
  </Link>
);

export default ArticlePreview;
