import React, { useState , useEffect, useCallback, useRef} from 'react';
import '../css/AddArticlePage.css'; 
import { createReview } from '../api/reviewAPI';
import {cityAutoComplete, roleAutoComplete } from '../api/utilAPI';
import { useNavigate } from 'react-router-dom'; 
import { debounce } from './Utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import SimpleMdeReact from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';

// import MarkdownEditor from '@uiw/react-markdown-editor';
// import ReactDOM from 'react-dom';



const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <button className="custom-date-picker-input" onClick={onClick} ref={ref}>
    {value}
    <FaCalendarAlt className="calendar-icon" />
  </button>
));



// TODO ADD CONDITION THAT USER HAS TO SELECT ONLY FROM THE DROPDOWN - ADD CORRESPONDING VALUE IN DROPDOWN IF CITY NOT IN LIST
// TODO ADD DROPDOWN TO ROLES AND COMPANIES

const AddArticlePage = ({ onSaveArticle }) => {
  const initialArticleData = {
    title: '',
    text: '',
    companyName: '',
    role: '',
    details: '',
    date: new Date(),
    salary:'',
    location: '',
    anonymous: false,
  };

  const [articleData, setArticleData] = useState(initialArticleData);
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [isLocationFocused, setIsLocationFocused] = useState(false); // State to track focus

  const [roleSuggestions, setRoleSuggestions] = useState([]);
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);
  const [isRoleFocused, setIsRoleFocused] = useState(false); // State to track focus on the role input

  const [isAnonymous, setIsAnonymous] = useState(false);
  



  const navigate = useNavigate();
  const latestValueRef = useRef();

// Debounce the cityAutocomplete call
const debouncedCitySearch = useCallback(debounce(async () => {
  const searchTerm = latestValueRef.current;
  setIsLoadingCities(true);
  try {
    if (searchTerm !== ""){
      setIsLocationFocused(true);
      const cities = await cityAutoComplete(searchTerm);
      setCitySuggestions(cities);
    }
    else{
      setIsLocationFocused(false);
    }
  } catch (error) {
    console.error('Failed to fetch city suggestions:', error);
  } finally {
    setIsLoadingCities(false);
  }
}, 500), []); // 500ms delay

const debouncedRoleSearch = useCallback(debounce(async () => {
  const searchTerm = latestValueRef.current;
  setIsLoadingRoles(true);
  try {
    if (searchTerm !== ""){
      setIsRoleFocused(true);
    const roles = await roleAutoComplete(searchTerm);
    setRoleSuggestions(roles);
    }
    else{
      setIsRoleFocused(false);
    }
  } catch (error) {
    console.error('Failed to fetch role suggestions:', error);
  } finally {
    setIsLoadingRoles(false);
  }
}, 500), []); // 500ms delay


  const handleChange = (e) => {
    const { name, value } = e.target;
    latestValueRef.current = value;
    setArticleData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'location' ) {
      debouncedCitySearch(value);
    } else if (name === 'role') {
      debouncedRoleSearch(value);
    };
  };

  const dateHandleChange = (date) => {
    setArticleData((prevData) => ({
      ...prevData,
      date: date,
    }));
  };

  const selectCity = (cityName) => {
    setArticleData(prevState => ({
      ...prevState,
      location: cityName
    }));
    setCitySuggestions([]); // Clear suggestions
    setIsLocationFocused(false); // Hide dropdown by removing focus
  };

  const selectRole = (job_title) => {
    setArticleData(prevState => ({
      ...prevState,
      role: job_title
    }));
    setRoleSuggestions([]); // Clear suggestions
    setIsRoleFocused(false); // Hide dropdown by removing focus
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsRoleFocused(false);
      setIsLocationFocused(false);
    }, 200); 
  };
  const handleCheckboxChange = () => {
    setIsAnonymous(!isAnonymous);
  };
  const handleDetailsChange = useCallback((value) => {
    setArticleData((prevData) => ({
      ...prevData,
      details: value,
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Print the current state before submission
      console.log('Current article data before submission:', articleData);
  
      // Update the articleData with the anonymous field
      const updatedArticleData = {
        ...articleData,
        anonymous: isAnonymous,
      };
  
      // Print the updated article data that will be submitted
      console.log('Article data being submitted:', updatedArticleData);
  
      const newArticle = await createReview(updatedArticleData);
      console.log('Article created:', newArticle);
      // onSaveArticle(newArticle); Uncomment this if onSaveArticle function is implemented and passed as props
      setArticleData(initialArticleData); // Reset the form to initial state
      navigate('/');
    } catch (error) {
      console.error('Failed to create article:', error);
    }
  };
  
  
  return (
    <div className="container-article">
      <h1 id="h1article">New Article</h1>
      <form onSubmit={handleSubmit} className="form-article">
        <div className="form-group">
          <label htmlFor="title">Article Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={articleData.title}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={articleData.companyName}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group col">
            <label htmlFor="date">Date</label>
              <DatePicker
                selected={articleData.date}
                onChange={dateHandleChange}
                dateFormat="MM-dd-yyyy"
                className="form-control"
                customInput={<CustomInput />}
              />
          </div>
          
        </div>
        <div className="row">
 
            <div className="form-group col">
            <label htmlFor="role">Role</label>
            <input
              type="text"
              id="role"
              name="role"
              value={articleData.role}
              onChange={handleChange}
              onFocus={() => setIsRoleFocused(true)}
              onBlur={handleBlur}
              className="form-control"
              autoComplete="off"
              required
            />
              {isRoleFocused && roleSuggestions.length > 0 && (
                <ul className="role-suggestions">
                  {roleSuggestions.map((role, index) => (
                    <li key={index} onClick={() => selectRole(role.job_title)}>
                      {role.job_title}
                    </li>
                  ))}
                </ul>
              )}
              </div>
              <div className="form-group col">

                <label htmlFor="salary">Salary Range</label>
                <input
                  type="text"
                  id="salary"
                  name="salary"
                  value={articleData.salary}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group col">
                <label htmlFor="location">Location</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={articleData.location}
                  onChange={handleChange}
                  onFocus={() => setIsLocationFocused(true)}
                  onBlur={handleBlur}
                  className="form-control"
                  autoComplete="off"
                  required
                />
                {isLocationFocused && citySuggestions.length > 0 && (
                  <ul className="city-suggestions">
                    {citySuggestions.map((city, index) => (
                      <li key={index} onClick={() => selectCity(city.cityName)}>
                        {city.cityName}, {city.stateCode}, {city.countryCode}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

          </div>
        <div className="form-group markdown-editor">
        <label htmlFor="text">Details</label>
        <SimpleMdeReact
          value={articleData.details}
          onChange={handleDetailsChange}
        />

        </div>
        <div className="checkbox-container">
  <input
    type="checkbox"
    id="anonymousCheckbox"
    checked={isAnonymous}
    onChange={handleCheckboxChange}
  />
  <label htmlFor="anonymousCheckbox">Post Anonymously</label>
</div>

        <button type="submit" className="btn btn-secondary btn-block">Submit Article</button>
        
      </form>
    </div>
  );
}  

export default AddArticlePage;
