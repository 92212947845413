// ArticleDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../css/ArticleDetail.css'; // Ensure the path is correct
import { fetchArticleById, fetchLikesForArticle, addLikeToArticle, removeLikeFromArticle } from '../api/reviewAPI'; // Adjust based on actual function and path
import { FaThumbsUp } from "react-icons/fa6";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import LikeButton from './LikeButton.js';
const ArticleDetail = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [likesCount, setLikesCount] = useState(0);
  const [hasLiked, setHasLiked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);


  useEffect(() => {
    const loadArticle = async () => {
      try {
        const data = await fetchArticleById(id);
        setArticle(data);
        const token = localStorage.getItem('token')
        if (token){ setIsLoggedIn(true)} else {setIsLoggedIn(false)};
        const response = await fetchLikesForArticle(id, token);
        
        setLikesCount(response.count);
        setHasLiked(response.hasLiked);

        setLoading(false);
      } catch (err) {
        setError(err.message || 'Failed to fetch article');
        setLoading(false);
      }
    };

    loadArticle();
  }, [id]);
 

  const handleLike = async() =>{
    try
    {
      await addLikeToArticle(id);
      setHasLiked(true);
      setLikesCount(likesCount + 1);
      
    }
    catch (err){
      setError(err.message || 'Failed to like the article');
    }
  };


  const handleUnlike = async() =>{
    try
    {
      await removeLikeFromArticle(id);
      setLikesCount(likesCount - 1);
      setHasLiked(false);
    }
    catch (err){
      setError(err.message || 'Failed to unlike the article');
    }
  };

  if (loading) return <div>Article Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="article-container">
      <h1 className="article-title">{article?.title}</h1>
      <div className="article-meta">
        <span><strong>Company Name:</strong> {article?.companyName}</span> 
        <span><strong>Year:</strong> {article?.year}</span> 
        <span><strong>Position:</strong> {article?.role}</span>
      </div>
     <h2>Details</h2>
      <p className="article-content">{article?.text}</p>
      <div className="article-likes">
      <div className="article-likes">
      <LikeButton
        likesCount={likesCount}
        isLoggedIn={isLoggedIn}
        hasLiked={hasLiked}
        handleLike={handleLike}
        handleUnlike={handleUnlike}
      />
    </div>

      
      </div>
      {/* Placeholder for future discussion/chat area */}
      <div className="article-discussion">
        <h2>Discussions</h2>
        <p>Placeholder for discussion/chat area. Coming soon...</p>
      </div>
    </div>
  );
};

export default ArticleDetail;
