import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import '../css/style.css'; 

const LikeButton = ({ likesCount, isLoggedIn, hasLiked, handleLike, handleUnlike }) => {
  const [showLoginMessage, setShowLoginMessage] = useState(false);

  const handleLoginAlert = () => {
    setShowLoginMessage(true);
    setTimeout(() => setShowLoginMessage(false), 3000); // Hide the message after 3 seconds
  };

  return (
    <div className="article-likes">
      {isLoggedIn ? (
        hasLiked ? (
          <Button
            content="Liked"
            icon="heart"
            color="green"
            label={{ as: 'a', basic: true, content: likesCount }}
            labelPosition="right"
            onClick={handleUnlike}
          />
        ) : (
          <Button
            content="Like"
            icon="heart outline"
            label={{ as: 'a', basic: true, content: likesCount }}
            labelPosition="right"
            onClick={handleLike}
          />
        )
      ) : (
        <Button
          content="Like"
          icon="heart outline"
          label={{ as: 'a', basic: true, content: likesCount }}
          labelPosition="right"
          onClick={handleLoginAlert}
        />
      )}
      {showLoginMessage && <div className="login-message">Please log in to like the article.</div>}
    </div>
  );
};

export default LikeButton;
