// ArticleList.js
import React, { useState } from 'react';
import ArticlePreview from './ArticlePreview';
import '../css/style.css';

const ArticleList = ({ articles }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 10;

  // Calculate the index of the first and last articles on the current page
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  // Extract the articles to display on the current page
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

  // Calculate the total number of pages
  const totalPages = Math.ceil(articles.length / articlesPerPage);

  // Function to change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      {currentArticles.map((article) => (
        <ArticlePreview key={article.id} article={article} />
      ))}
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button key={index + 1} onClick={() => paginate(index + 1)}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ArticleList;


