import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchUserProfile } from '../api/userAPI'; // Adjust the path as needed
import "../css/ProfilePage.css"
import defaultProfilePic from '../assets/image/defaultpic.jpeg';
import { useUser } from '../contexts/UserContext'; // Import the useUser hook

function UserProfile({ username: propUsername }) {
    const { username: urlUsername } = useParams();
    const username = propUsername || urlUsername; // Use the prop username if provided, otherwise use the URL username
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const loadUserProfile = async () => {
        try {
          const profileData = await fetchUserProfile(username);
          setProfile(profileData.user);
        } catch (error) {
          setError(error.message);
        }
      };
  
      if (username) {
        loadUserProfile();
      }
    }, [username]);

  if (error) return <div>Error: {error}</div>;
  if (!profile) return <div>Loading...</div>;

  return (
    <div className="profile-page">
      <div className="profile-header">
        <img src={profile.photoUrl || defaultProfilePic} alt="Profile" className="profile-pic" />
        <h1 className="profile-name">{profile.fullName}</h1>
      </div>
      <div className="profile-details">
        <p><strong>About:</strong> {profile.about}</p>
        <p><strong>College:</strong> {profile.collegeName}</p>
        <p><strong>LinkedIn:</strong> <a href={profile.linkedIn} target="_blank" rel="noopener noreferrer">{profile.linkedIn}</a></p>
        <p><strong>GitHub:</strong> <a href={profile.gitHub} target="_blank" rel="noopener noreferrer">{profile.gitHub}</a></p>
      </div>
    </div>
  );
}

export default UserProfile;
