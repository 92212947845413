const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
const AUTH_API_URL = `${API_BASE_URL}/api/auth`;

//Function to handle otp verification
export const verifyOTP = async ({ email, otp }) => {
    try {
      const response = await fetch(`${AUTH_API_URL}/verify-otp`, { // Assuming your endpoint is /verify-otp
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'OTP verification failed');
      }
  
      return await response.json(); // The response from the server after successful verification
    } catch (error) {
      console.error("OTP Verification Error:", error);
      throw error; // Rethrowing the error to be handled where the function is called
    }
  };


  export const sendResetPasswordEmail = async(email) => {
    try {
        const response = await fetch(`${AUTH_API_URL}/forgot-password`, {
          method: 'POST',
          headers: {
            'Content-Type' : 'application/json',
          },
          body: JSON.stringify({email}),
        });
  
      if (!response.ok){
        throw new Error("Failed to send backend request to email password-reset")
      }
  
      return await response.json();
    } catch (error) {
      console.error("Passwort Reset Email Error:", error);
      throw error;
    }
  };

export const resetUserPassword = async(token, password) => {
  const response = await fetch(`${AUTH_API_URL}/reset-password?token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ password }),
  });

  if (!response.ok) {
    // Convert non-2xx HTTP responses into errors
    const errorBody = await response.json();
    throw new Error(errorBody.message || 'Failed to reset password');
  }

  // Optionally, return some data or simply resolve the promise
  return await response.json();

}