import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetUserPassword } from '../api/authAPI'; // Placeholder for your API call function

function ResetPasswordPage() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    try {
      await resetUserPassword(token, password);
      setMessage('Your password has been updated.');
      navigate('/login');
    } catch (error) {
      setMessage('Failed to reset password. Please try again.');
    }
  };

  return (
    <div className="reset-password-page">
      <h2>Set New Password</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <label>
          New Password
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </label>
        <label>
          Confirm New Password
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </label>
        <button type="submit">Save New Password</button>
      </form>
    </div>
  );
}

export default ResetPasswordPage;
