// Header.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Header.css'; // Path to your CSS file
import ThemeSwitcher from './ThemeSwitcher';
import logoImage from '../assets/image/logo1.webp';
import { useUser } from '../contexts/UserContext';
import { FaUserCircle } from "react-icons/fa";
import { CiUser } from "react-icons/ci";

function Header() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('isDarkMode');
    // Default to dark mode if there's no preference in local storage
    return savedTheme === null ? true : savedTheme === 'true';
  });
  //const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem('isDarkMode') === 'true');
  const { user, setUser } = useUser(); // Access user state and setUser function
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false); 
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // Update the class list and local storage when isDarkMode changes
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
      document.body.classList.remove('light-mode');
      document.body.style.backgroundColor = 'var(--color-dark)';
    } else {
      document.body.classList.remove('dark-mode');
      document.body.classList.add('light-mode');
      document.body.style.backgroundColor = 'var(--color-light)';
    }
    localStorage.setItem('isDarkMode', isDarkMode.toString());
  }, [isDarkMode]);

  const handleToggleTheme = () => {
    setIsDarkMode(prevMode => !prevMode);
  };
  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token from localStorage
    setUser(null); // Update user state to null
    navigate('/');
  };
  const handlePublicProfile = () => {
    // const username = localStorage.getItem('username');
    // console.log("hell,", user.username)
    navigate(`/user/${user.username}`); // Redirect to the user's profile
  };
  const handleEditProfile = () => {
    // Navigate to edit profile page
    navigate(`/profile`);
  };
  return (
    <header className="site-header">
      <nav className="navbar">
        <div className="nav-brand">
          <a href="/">
            <img src={logoImage} alt="Website Logo" className="website-logo" />
            <span className="website-name">InterviewBlogs</span>
          </a>
        </div>
        <ul className="nav-links">
          {/* <li><a href="/categories">Categories</a></li> */}
          <li><a href="/trending">Trending</a></li>
          <li><a target="_blank" href="https://h1bdata.info">H1B Info</a></li>
          <li><a target="_blank" href="https://www.overleaf.com/gallery/tagged/cv">Resume</a></li>
          <li><a target="_blank" href="https://www.jobseekr.io/">Job Search</a></li>
        </ul>
        <ul className="auth-links">
        {user ? (
    <>
  <li>
  <div className="avatar-container" onClick={toggleDropdown}>
    {/* <img src={userAvatar} alt="Avatar" className="avatar" /> */}
    {/* <FaUserCircle  /> */}
    <CiUser size="2em" />


  
  {showDropdown && (
   <ul className="dropdown-menu ">
   <li>
     <div className="dropdown-item" onClick={handlePublicProfile}>
       Public Profile
     </div>
   </li>
   <li>
     <div className="dropdown-item" onClick={handleEditProfile}>
       Edit Profile
     </div>
   </li>
   <li>
     <div className="dropdown-item" onClick={handleLogout}>
       Logout
     </div>
   </li>
 </ul>
 
  )}
  </div>
</li>


    </>
          ) : (
            <>
              <li><a href="/login">Log in</a></li>
              <li><a href="/signup">Sign up</a></li>
            </>
          )}
          <li className="theme-switcher">
            <ThemeSwitcher isDarkMode={isDarkMode} onToggle={handleToggleTheme} />
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
