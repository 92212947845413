import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { signupUser } from '../api/userAPI';
import { verifyOTP } from '../api/authAPI';
import "../css/SignupPage.css";
import logoImage from '../assets/image/logo1.webp';

function SignupPage() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    otp: '',
  });
  const [error, setError] = useState('');
  const [awaitingOTP, setAwaitingOTP] = useState(false);
  const navigate = useNavigate(); // Initialize navigate function

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const data = await signupUser({
        username: formData.username,
        email: formData.email,
        password: formData.password
      });
      console.log('Signup Success:', data);
      setAwaitingOTP(true); // Move to OTP screen
    } catch (error) {
      console.log(error);
      console.error('Signup Failed:', error.message);
      setError(error.message || 'Signup Failed. Please try again.');
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await verifyOTP({
        email: formData.email,
        otp: formData.otp
      });
      console.log('OTP Verification Success');
      navigate('/login'); // Redirect to login page using React Router
    } catch (error) {
      console.error('OTP Verification Failed:', error);
      setError(error.message || 'OTP verification failed. Please try again.');
      
      // Assuming the error message indicating redirection is specific and identifiable
      if (error.message.includes('Maximum OTP verification')) {
        setError(error.message + ' Reloading .....')
        setTimeout(() => window.location.href = '/signup', 1500); // Delaying navigation to ensure it's not blocked
    }
    }
  };
  return (
    <main id="sp-pageContent" className="sp-tt-offset-none">
      <div className="sp-container">
        <div className="sp-tt-loginpages-wrapper">
          <div className="sp-tt-loginpages">
            <a href="/" className="sp-tt-block-title">
              <img src={logoImage} alt="Logo" className="login-logo" />
              <div className="sp-tt-title">
                Welcome to InterviewBlogs
              </div>
              <div className="sp-tt-description">
                Join the forum to unlock the true power of community.
              </div>
            </a>
            {!awaitingOTP ? (
              // Signup form
              <form className="sp-form-default" onSubmit={handleSignupSubmit}>
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <div className="sp-form-group">
                  <label htmlFor="sp-loginUserName">Username</label>
                  <input type="text" name="username" className="sp-form-control" id="sp-loginUserName" placeholder="JohnDoe" value={formData.username} onChange={handleChange} />
                </div>
                <div className="sp-form-group">
                  <label htmlFor="sp-loginUserEmail">Email</label>
                  <input type="email" name="email" className="sp-form-control" id="sp-loginUserEmail" placeholder="johndoe@gmail.com" value={formData.email} onChange={handleChange} />
                </div>
                <div className="sp-form-group">
                  <label htmlFor="sp-loginUserPassword">Password</label>
                  <input type="password" name="password" className="sp-form-control" id="sp-loginUserPassword" placeholder="************" value={formData.password} onChange={handleChange} />
                </div>
                <div className="sp-form-group">
                  <button type="submit" className="sp-btn sp-btn-secondary sp-btn-block">Create my account</button>
                </div>
                <p className="heelop">Already have an account? <a href="/login" className="sp-tt-underline">Login here</a></p>
                {/* <div className="sp-tt-notes">
                  By signing up, signing in or continuing, I agree to
                  InterviewBlogs <a href="#" className="sp-tt-underline">Terms of Use</a> and <a href="#" className="sp-tt-underline">Privacy Policy.</a>
                </div> */}
              </form>
            ) : (
              // OTP form
              <form className="sp-form-default" onSubmit={handleOTPSubmit}>
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <div className="sp-form-group">
                  <label htmlFor="sp-loginUserOTP">OTP</label>
                  <input type="text" name="otp" className="sp-form-control" id="sp-loginUserOTP" placeholder="Enter your OTP" value={formData.otp} onChange={handleChange} />
                </div>
                <button type="submit" className="sp-btn sp-btn-secondary sp-btn-block">Verify OTP</button>
              </form>
            )}
          </div>
        </div>
      </div>
    </main>
  );
            };  

export default SignupPage;
