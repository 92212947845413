import React, { useState, useEffect } from 'react';
import Header from './Header';
import FilterBar from './FilterBar';
import ArticleList from './ArticleList';
import '../css/style.css'; 
import AddArticlePage from './AddArticlePage'; 
import ThemeSwitcher from './ThemeSwitcher';
import { fetchReviews } from '../api/reviewAPI'; 
import { Link } from 'react-router-dom';

const MainPage = () => {
  const [filters, setFilters] = useState({
    companyName: '',
    role: '',
    location: '',
  });
  const [articles, setArticles] = useState([]);
  const [showForm, setShowForm] = useState(false);
  // Removed [filteredArticles, setFilteredArticles] state as filtering is now handled by the backend

  const handleSaveArticle = (newArticle) => {
    setArticles(prevArticles => [...prevArticles, newArticle]);
    setShowForm(false); // Hide the form after saving
  };

  useEffect(() => {
    const loadReviews = async () => {
      try {
        const queryParams = new URLSearchParams();

      // Only add parameters that have values
      if (filters.companyName) queryParams.append('companyName', filters.companyName);
      if (filters.role) queryParams.append('role', filters.role);
      if (filters.location) queryParams.append('location', filters.location);

      // Repeat for any other filters you have
      console.log(queryParams.toString());
        const data = await fetchReviews(queryParams.toString()); // Update fetchReviews to accept queryParams
        setArticles(data); // Assuming this sets the original list of articles
      } catch (error) {
        console.error('Failed to fetch reviews:', error);
      }
    };

    loadReviews();
  }, [filters]); // Depend on filters to re-fetch when they change

  // Removed the useEffect hook that updates filteredArticles based on filters

  return (
    <div>
      {/* Assuming ThemeSwitcher and other components like Header might still be part of your app, include them as needed */}
      <FilterBar onSearch={(filterValues) => setFilters(filterValues)} />
      <ArticleList articles={articles} />
      {/* Assuming you might want to toggle showing a form to add an article, include it as needed */}
    </div>
  );
};

export default MainPage;
