import React, { useState, useEffect, useRef } from 'react';
import "../css/ProfilePage.css";
import defaultProfilePic from '../assets/image/defaultpic.jpeg';
import { updateProfile, fetchUserProfile } from '../api/userAPI'; // Import the fetchUserProfile function
import { useUser } from '../contexts/UserContext';

function ProfilePage() {
  const { user } = useUser();
  const [profile, setProfile] = useState({
    fullName: '',
    about: '',
    collegeName: '',
    linkedIn: '',
    gitHub: '',
    photoUrl: defaultProfilePic,
  });
  const [success, setSuccess] = useState(false); // State to track success
  const fileInputRef = useRef(null);
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        // Use the username from the user context to fetch the profile
        const profileData = await fetchUserProfile(user.username);
        console.log("new edit");
        setProfile(profileData.user);

      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    if (user && user.username) {
      fetchProfileData();
    }
  }, [user]);


  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'picture') {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfile((prevState) => ({
          ...prevState,
          picture: e.target.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setProfile((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    console.log('log change:', profile); // Log the updated profile state
  };
  

  const handlePictureClick = () => {
    fileInputRef.current.click();
  };
  // const username = user ? user.username : ''; // or use your user context to get the user ID


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     // Retrieve the user ID
  //     console.log(profile)
  //     console.log('User data inside function:', user);
  //     const profileData = {
  //       token:user.token, 
  //       fullName: profile.fullName,
  //       about: profile.about,
  //       collegeName: profile.collegeName,
  //       linkedIn: profile.linkedIn.trim(),
  //       gitHub: profile.gitHub.trim(),
  //       // photoUrl: profile.photoUrl, // Handle picture separately if needed TODO
  //     };
  
  //     // Call the API to update the profile
  //     console.log(profileData);
  //     const updatedProfile = await updateProfile(profileData);
  //     console.log('Profile updated:', updatedProfile);
  
  //     // Update the local state with the updated profile data if needed
  //     // setProfile(updatedProfile);
  
  //   } catch (error) {
  //     console.error('Error updating profile:', error);
  //     // Optionally, set an error state here to show an error message
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedProfile = await updateProfile({ ...profile, token: user.token });
      setProfile(updatedProfile);
      setSuccess(true); // Set success to true
      setTimeout(() => setSuccess(false), 5000);
    } catch (error) {
      console.error('Error updating profile:', error);
      setSuccess(false); // Set success to false in case of error
    }
  };

  return (
    <div className="profile-page">
      <h1>Profile Page</h1>
      
      <form onSubmit={handleSubmit}>
      <div className="form-group profile-picture">
        <img src={profile.photoUrl} alt="Profile" onClick={handlePictureClick} />
        <input
          type="file"
          name="photoUrl"
          ref={fileInputRef}
          onInput={handleChange}
          style={{ display: 'none' }}
        />
      </div>
        <div className="form-group profileform">
          <label htmlFor="fullName">Name</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={profile.fullName}
            onInput={handleChange}
          />
        </div>
        <div className="form-group profileform">
          <label htmlFor="about">About</label>
          <input
            type="text"
            id="about"
            name="about"
            value={profile.about}
            onInput={handleChange}
          />
        </div>
        <div className="form-group profileform">
          <label htmlFor="collegeName">College</label>
          <input
            type="text"
            id="collegeName"
            name="collegeName"
            value={profile.collegeName}
            onInput={handleChange}
          />
        </div>
        <div className="form-group profileform">
          <label htmlFor="linkedIn">LinkedIn</label>
          <input
          type="text"
          id="linkedIn"
          name="linkedIn" // Change from 'linkedin' to 'linkedIn'
          value={profile.linkedIn}
          onInput={handleChange}
          />
        </div>
        <div className="form-group profileform">
          <label htmlFor="gitHub">Github</label>
          <input
          type="text"
          id="gitHub"
          name="gitHub" // Change from 'github' to 'gitHub'
          value={profile.gitHub}
          onInput={handleChange}
        />
        </div>
        
        <button className="profileButton" type="submit">Save Profile</button>
      </form>
      {success && <div className="alert alert-success">Profile saved successfully!</div>}
      {/* {!success && <div className="alert alert-failure">Profile not saved!</div>} */}
    </div>
  );
}

export default ProfilePage;
